import { post } from 'utils';

export default function verifyRetailer(
  params = {},
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {}
) {
  post({ apiKey: 'retailerVerify' }, { params })
    .then(({ data }) => onSuccess(data))
    .catch(onError)
    .finally(onFinally);
}
