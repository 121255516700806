import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { useStoreConnection } from 'utils/hooks';
import { AlertBanner, ContainerCard, StoreConnectHeader } from '../components';

const ConnectStoreContext = createContext({
  referrer: '',
  trialPeriod: '',
  isConnecting: false,
  connect: (_connectData = {}) => false,
  isError: false,
  errorMessage: ''
});

/**
 * @param {Object} arguments
 * @param {import("utils/types").EcommPlatformSource} arguments.store
 * @param {import("react").ReactNode} arguments.children
 */
export function ConnectStoreProvider({ store = '', children = null }) {
  const { search } = useLocation();
  const { isConnecting, onConnect } = useStoreConnection();

  const { referrer, free_trial: trialPeriod, error, msg } = qs.parse(
    search.slice(1)
  );
  const isError = error === '1';
  const errorMessage = isError && msg ? msg : '';

  const connect = (data = null) => {
    const queryData = { ...data };
    if (referrer && trialPeriod) {
      queryData.referrer = referrer;
      queryData.trial_period = trialPeriod;
    }
    onConnect(store, queryData);
  };

  return (
    <ConnectStoreContext.Provider
      value={{
        connect,
        isConnecting,
        referrer,
        trialPeriod,
        isError,
        errorMessage
      }}
    >
      <AlertBanner />
      <ContainerCard title={<StoreConnectHeader store={store} />}>
        {children}
      </ContainerCard>
    </ConnectStoreContext.Provider>
  );
}

export function useConnectStore() {
  const context = useContext(ConnectStoreContext);

  if (!context) {
    throw new Error(
      'useConnectStore must be used within a ConnectContextProvider'
    );
  }

  return context;
}
