import React from 'react';
import _get from 'lodash/get';

import { UiKit } from 'components';
import { StoreUrlInput } from 'views/ConnectStore/components';
import { STORE_URL_PREFIX, STORE_URL_SUFIX } from 'views/ConnectStore/stubs';

export default function Form({
  errors = {},
  values = {},
  isProcessing = false,
  handleBlur = () => {},
  handleChange = () => {},
  handleSubmit = () => {}
}) {
  const storeName = _get(values, 'storeName', '');
  const error = _get(errors, 'storeName', '');

  return (
    <form onSubmit={handleSubmit}>
      <StoreUrlInput
        name="storeName"
        value={storeName}
        onBlur={handleBlur}
        onChange={handleChange}
        label="Shopify Store Name"
        prefix={STORE_URL_PREFIX.SHOPIFY}
        suffix={STORE_URL_SUFIX.SHOPIFY}
        error={error}
      />
      <UiKit.Button
        type="submit"
        disabled={!storeName}
        processing={isProcessing}
        processingText="Connecting..."
        wrapperClassName="mt-4"
        className="w-100"
      >
        Install ReturnRabbit App
      </UiKit.Button>
    </form>
  );
}
