import { useCallback, useState } from 'react';
import qs from 'qs';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isPlainObject from 'lodash/isPlainObject';

/**
 * @type {Record<import("utils/types").EcommPlatformSource, *>}
 */
const OAUTH_BEGIN_API_ROUTE = {
  SHOPIFY: '/auth/shopify/begin/',
  WOOCOMMERCE: '/auth/woo/begin/',
  BIGCOMMERCE: '/auth/big/finalize/'
};

export default function useStoreConnection() {
  const [connectingStore, setConnectingStore] = useState(null);

  const onConnect = useCallback(
    /**
     * @param {import("utils/types").EcommPlatformSource} ecommPlatform
     * @param {object} payload
     */
    (ecommPlatform = '', payload = null) => {
      const route = OAUTH_BEGIN_API_ROUTE[ecommPlatform];

      if (!_isPlainObject(payload) || _isEmpty(payload) || !route) return;

      const store = _get(payload, 'shop', '');
      setConnectingStore(store);
      const queryString = qs.stringify(payload);
      window.location = `${process.env.REACT_APP_BASE_URL}${route}?${queryString}`;
    },
    []
  );

  return {
    onConnect,
    connectingStore,
    isConnecting: !!connectingStore
  };
}
