import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import styles from './index.module.scss';

import { CustomToast } from 'components';
import { AuthContext } from 'context/AuthContext';
import { useMutation } from 'utils/hooks';
import ssImage from 'assets/ss-icons/shipstation-icon.png';

export default function SellerSignIn() {
  const { search } = useLocation();
  const { replace } = useHistory();
  const { login } = useContext(AuthContext);

  const queryData = qs.parse(search.slice(1));

  const { mutate: sellerSign } = useMutation(
    'authentication.sellerSignIn',
    'POST',
    {
      payload: {
        ...(queryData || {})
      },
      onSuccess: data => {
        login(data);
        replace('/');
      },
      onError: err => {
        replace('/login');
        CustomToast({
          isNotified: err.notified,
          type: 'error',
          msg: "We couldn't log you in. Please try again."
        });
      }
    }
  );

  useEffect(() => {
    sellerSign();
  }, [login, replace, sellerSign]);

  return (
    <div className={styles.container}>
      <img className={styles.ssImage} src={ssImage} alt="not found" />
      <span className={styles.content}>
        Provisioning access to configuration portal...
      </span>
    </div>
  );
}
