import React, { useContext, useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If, isSSRTheme, localStore } from 'utils';
import { verifyRetailer } from 'utils/services';
import { AuthContext } from 'context/AuthContext';
import { EcommPlatfrom, UiKit } from 'components';
import { useAppContext } from 'utils/hooks';

export default function StoreSwitch({ className = '', itemClassName = '' }) {
  const [toStore, setToStore] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  // DEPRECATED CODE - 21/02/2025
  // const [needPassword, setNeedPassword] = useState(false);
  // const [password, setPassword] = useState('');
  // const [passwordError, setPasswordError] = useState('');
  // const passwordInputRef = useRef(null);
  const { storeProperties, permittedStores } = useContext(AuthContext);

  // const email = _get(userData, 'email', '');
  const loggedInStoreName = _get(storeProperties, 'store_unique_name', '');
  const isProcessing = isSwitching || isLoggingIn || isValidating;
  const { isSSRMode } = useAppContext();

  useEffect(() => {
    if (isSwitching && toStore) {
      const timer = setTimeout(() => {
        localStore.put('rrloggedInStoreName', toStore.name);
        if (isSSRTheme()) {
          localStore.put('rrloggedInStoreGuId', toStore.id);
        } else {
          localStore.put('rrloggedInStoreId', toStore.id);
        }
        window.location.href = '/';
        clearTimeout(timer);
      }, [500]);
    }
  }, [toStore, isSwitching]);

  // useEffect(() => {
  //   if (needPassword && passwordInputRef.current) {
  //     passwordInputRef.current.focus();
  //   }
  // }, [needPassword]);

  const onStoreSwitch = (id, name) => {
    if (!id) return;
    setToStore({
      id,
      name
    });
    setIsValidating(true);
    verifyRetailer(
      {
        ...(isSSRTheme() ? { seller_store_guid: id } : { rr_store_id: id })
      },
      () => setIsSwitching(true),
      err => {
        if (isSSRMode) return;
        // setNeedPassword(err.statusCode % 400 < 100);
      },
      () => setIsValidating(false)
    );
  };

  // const onLogin = e => {
  //   e.preventDefault();
  //   if (!password) return setPasswordError('Please enter the password');
  //   setIsLoggingIn(true);
  //   login(
  //     { email, password, retailer_name: toStore.name },
  //     () => {
  //       setIsSwitching(true);
  //       // setNeedPassword(false);
  //     },
  //     () => {
  //       setPasswordError('Incorrect Password! Please try again.');
  //       const timer = setTimeout(() => {
  //         if (passwordInputRef) {
  //           passwordInputRef.current.focus();
  //           passwordInputRef.current.select();
  //         }
  //         clearTimeout(timer);
  //       }, 0);
  //     },
  //     () => setIsLoggingIn(false)
  //   );
  // };

  // const onPasswordChange = (_, val) => {
  //   setPassword(val);
  //   setPasswordError('');
  // };

  const onCloseModal = () => {
    setToStore(null);
    // setPassword('');
    // setPasswordError('');
    setIsSwitching(false);
    setIsLoggingIn(false);
    setIsValidating(false);
    // setNeedPassword(false);
  };

  const storesList = permittedStores.reduce(
    (
      acc,
      {
        name,
        // For both RR and SSR the key is the same but values are different SSR(GUID) and RR(ID)
        seller_store_guid,
        rr_store_id,
        ecommerce_platform: platform
      }
    ) => {
      const storeId = isSSRTheme() ? seller_store_guid : rr_store_id;
      if (name !== loggedInStoreName) {
        acc.push(
          <DropdownItem
            key={storeId}
            className={itemClassName}
            onClick={() => onStoreSwitch(storeId, name)}
          >
            <EcommPlatfrom.Icon
              source={platform}
              className={styles.eCommerceIcon}
            />{' '}
            {name}
          </DropdownItem>
        );
      }
      return acc;
    },
    []
  );

  const modalClassName = cn(styles.modal, { [styles.expanded]: false });

  return (
    <>
      <DropdownMenu right className={className}>
        <DropdownItem
          // key="static-option"
          className={styles.disabled}
        >
          Switch to another store:
        </DropdownItem>
        <div className={styles.container}>{storesList}</div>
      </DropdownMenu>
      <UiKit.Modal
        centered
        isOpen={!!toStore}
        onClose={onCloseModal}
        isProcessing={isProcessing}
        contentClassName={modalClassName}
        headerClassName={styles.header}
      >
        <If test={isValidating || isSwitching}>
          <div className="d-flex align-items-center">
            {(isValidating || isSwitching) && (
              <i className="fas fa-spin fa-spinner" />
            )}
            <span className="ml-3">
              {isValidating ? (
                'Verifying access...'
              ) : isSwitching ? (
                <>
                  Switching to <strong>{toStore.name}</strong>
                </>
              ) : (
                ''
              )}
            </span>
          </div>
        </If>
        {/* <If test={needPassword && toStore}>
          <form className={styles.loginForm} onSubmit={onLogin}>
            <span className="mb-3">
              Login to <strong>{toStore?.name}</strong>
              <LockIcon className={styles.lockIcon} />
            </span>

            <div className={styles.helptext}>
              <span role="img" aria-label="info">
                💡
              </span>
              <p>
                You need to provide your password and log in only once per store
                and then you can switch seamlessly between all logged in stores.
              </p>
            </div>

            <UiKit.Label
              className={styles.label}
              text={
                <>
                  Please enter the password for <strong>{toStore?.name}</strong>{' '}
                  store
                </>
              }
            />
            <UiKit.Input
              type="password"
              placeholder="*************"
              inputRef={passwordInputRef}
              value={password}
              onChange={onPasswordChange}
              disabled={isLoggingIn}
              error={passwordError}
              className={styles.inputContainer}
            />
            <UiKit.Button
              customType="success"
              wrapperClassName={styles.loginButton}
              processing={isLoggingIn}
              processingText="Verifying..."
            >
              Login
            </UiKit.Button>
          </form>
        </If> */}
      </UiKit.Modal>
    </>
  );
}
